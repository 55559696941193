import './App.css';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {CBusAppBar} from './components/Header/CBusAppBar.jsx';
import {Splash} from './components/Splash.jsx';
import {BrowserRouter as Router} from 'react-router-dom';
import {Auth0Provider} from "@auth0/auth0-react";
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {createMyTheme, myThemeOptions} from './themes/Themes.jsx';
import {ThemeToggleContext} from "./ThemeToggleContext.mjs";

// TODO: Avoid hardcoding these values
const domain = "jackiergleason.auth0.com";
const clientId = "LCJAAEbovLTRyzqEqNVtWlE4VWyecGtW";

// import { useAuth0 } from '@auth0/auth0-react';

// Create a new context
export const LoadingContext = createContext();

// TODO: Can probably removed since moved to splash
// Create a provider component for this context
// export const LoadingProvider = ({ children }) => {
//     const { isLoading } = useAuth0();
//
//     return (
//         <LoadingContext.Provider value={isLoading}>
//             {children}
//         </LoadingContext.Provider>
//     );
// };

function App() {
    // const { isLoading } = useAuth0();
    const [mode, setMode] = useState('light');

    const theme = createMyTheme({...myThemeOptions, palette: {...myThemeOptions.palette, mode}});
    const toggleTheme = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
    const [redirectUrl, setRedirectUrl] = useState();
    useEffect(() => {
        setRedirectUrl(window.location.origin);
    }, []);
    // if (isLoading) {
    //     return <div>Loading...</div>; // or replace with a loading indicator
    // }
    return redirectUrl && (
        <ThemeToggleContext.Provider value={toggleTheme}>
            <ThemeProvider theme={theme}>
                <Auth0Provider
                    // @ts-ignore
                    domain={domain}
                    // @ts-ignore
                    clientId={clientId}
                    useRefreshTokens={true}
                    cacheLocation={"localstorage"}
                    // TODO: Avoid hardcoding these values
                    advancedOptions={{defaultScope: 'email'}}
                    authorizationParams={{
                        redirect_uri: redirectUrl,
                        audience: "https://cbusha.com",
                        defaultScope: "email",
                    }}
                >
                    <Router>
                        <CssBaseline/>
                        <CBusAppBar/>
                        <main style={{height: "100%"}}>
                            <Splash/>
                        </main>
                    </Router>
                </Auth0Provider>
            </ThemeProvider>
        </ThemeToggleContext.Provider>
    );
}

export default App;
