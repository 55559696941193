import {Box} from "@mui/material";
import {MarketedServices} from "./MarketedServices.jsx";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

export function MarketingPage() {
    return (
        <React.Fragment>
            <AppBar
                elevation={0}
                position={"relative"}
                sx={{ // Add this line
                    backgroundColor: 'black', // And this line
                }}
            >
                <Toolbar sx={{
                    alignItems: 'stretch',
                }}>
                </Toolbar>
            </AppBar>
            <Box width={"100%"} height={"70vh"} bgcolor="black">
                <video autoPlay loop style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                    <source src="/4kdemo.mp4" type="video/mp4"/>
                    {/* Add additional source tags for different video formats if needed */}
                    Your browser does not support the video tag.
                </video>
            </Box>
            <MarketedServices/>
        </React.Fragment>
    )
}
