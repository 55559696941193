import {useAuth0} from "@auth0/auth0-react";
import {Menu, MenuItem} from "@mui/material";
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import {useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";

function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
        </SvgIcon>
    );
}

export function UserMenu({anchorEl, setAnchorEl}) {
    const {
        isAuthenticated,
        loginWithRedirect,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
        logout,
    } = useAuth0();
    const navigate = useNavigate();
    const handleLogin = () => {
        loginWithRedirect(); // Redirect the user to the Auth0 login page
    };

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    };

    const handleTokenClick = async () => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (e) {
            token = await getAccessTokenWithPopup();
        }
        console.log(token);
        return token;
    };
    const goToCustomer = () => {
        navigate('/customer');
        handleClose();
    };
    const goToContact = () => {
        navigate('/contact');
        handleClose();
    };

    const goToHome = () => {
        navigate('/');
        handleClose();
    };


    const goToIdTokens = () => {
        navigate('/id-claims');
        handleClose();
    }
    const goToFinance = () => {
        navigate('/finance');
        handleClose();
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu open={Boolean(anchorEl)}
              anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
              }}
              PaperProps={{
                  style: {
                      width: "200px",
                      borderRadius: "0",
                      left: "0"
                  },
              }}
              anchorEl={anchorEl}
              onClose={handleClose}>
            {!isAuthenticated ? (
                <Box>
                    <MenuItem onClick={goToContact}>Contact Us</MenuItem>
                    <MenuItem style={{justifyContent: "center"}} onClick={handleLogin}>Login</MenuItem>
                </Box>
            ) : (
                <Box>
                    {/*TODO: Add back once permissions are handled on the ui*/}
                    {/*<MenuItem onClick={goToFinance}>Personal Finance</MenuItem>*/}
                    {/*<MenuItem onClick={goToCustomer}>Customers</MenuItem>*/}
                    {/*<MenuItem onClick={goToIdTokens}>ID Tokens</MenuItem>*/}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Box>
            )}
        </Menu>
    )
}