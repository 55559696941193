import React from "react";
import {MarketingPage} from "../Marketing/MarketingPage.jsx";
import {Dashboard} from "../Dashboard/Dashboard.jsx";
import {useAuth0} from "@auth0/auth0-react";

function VideoBackground() {
    return (
        <div className="relative">
            <div style={{
                height: "100%",
                backgroundColor: "#000000"
            }}>
                <video autoPlay loop>
                    <source src="/4kdemo.mp4" type="video/mp4"/>
                    {/* Add additional source tags for different video formats if needed */}
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="absolute top-10 left-10 p-4">
                <div className="text-5xl font-bold">Columbus Home Automation
                </div>
                <div className="text-3xl font-bold">614's best home automation support
                </div>
                <div className="text-xl font-bold">Coming Soon!</div>
            </div>
        </div>
    );
}

export const Home = () => {
    // TODO: Flashes when you are logged in since you start off not logged in
    // Need to make it so it ensures it is loaded before rendering.
    const {isAuthenticated, isLoading} = useAuth0();
    if (isLoading) {
        return null; // or replace with a loading spinner
    }
    return (
        <React.Fragment>
            {isAuthenticated ? (
                <Dashboard></Dashboard>
            ) : (
                <MarketingPage></MarketingPage>
            )}

        </React.Fragment>
    )
}