import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {List, ListItem, ListItemText, Paper, Typography} from '@mui/material';

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substr(0, maxLength - 3) + '...';
};

const IdTokenClaimsTable = () => {
    const {getIdTokenClaims} = useAuth0();
    const [claims, setClaims] = useState(null);

    useEffect(() => {
        const fetchClaims = async () => {
            try {
                const idTokenClaims = await getIdTokenClaims();
                setClaims(idTokenClaims);
            } catch (error) {
                console.error('Error fetching id_token claims:', error);
            }
        };

        fetchClaims();
    }, [getIdTokenClaims]);
    const renderClaimValue = (claim, value) => {
        if (claim === 'picture') {
            return <img src={value} alt={claim} style={{maxWidth: '200px', height: 'auto'}}/>;
        }
        return value;
    };

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <List>
                {claims &&
                    Object.entries(claims).map(([claim, value]) => (
                        <ListItem key={claim}>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1" component="div" sx={{fontWeight: 'bold'}}>
                                        {truncateText(claim, 20)}
                                    </Typography>
                                }
                                secondary={renderClaimValue(claim, value)}
                            />
                        </ListItem>
                    ))}
            </List>
        </Paper>
    );
};

export default IdTokenClaimsTable;