import React, {useEffect, useState} from 'react';
import {Stack, TextField} from '@mui/material';
import Customer from './Customer.jsx';

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('/api/customer');
                const data = await response.json();
                setCustomers(data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    const handleSearch = async (event) => {
        setSearchTerm(event.target.value);

        try {
            const response = await fetch(`http://localhost:8080/customer/lastname/${event.target.value}`);
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error('Error searching customers by last name:', error);
        }
    };

    return (
        <div>
            <TextField
                label="Search by Last Name"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
            />

            <Stack spacing={2} mt={2}>
                {customers.map((customer) => (
                    <Customer key={customer.id} customerId={customer.id}/>
                ))}
            </Stack>
        </div>
    );
};

export default CustomerList;