import React from 'react';
import Box from '@mui/material/Box';
import {Route, Routes} from 'react-router-dom';
import {ContactForm} from './ContactPage/ContactForm.jsx';
import {Home} from './HomePage/Home.jsx';
import CustomerList from "./Customer/CustomerList.jsx";
import IdClaimsTable from "./IdClaims/IdClaimsTable.jsx";
import Divider from '@mui/material/Divider';
import {Link, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FinanceDashboard from "./FinancePage/FinanceDashboard.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {useAuth0} from "@auth0/auth0-react";

function Footer() {
    return (
        <Box display="flex"
             width="100%">
            <Box flex="1"
                 display="flex"
                 justifyContent="center"
                 padding="8">
                {/*<h1>footer</h1>*/}
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem/>
            <Box flex="1"
                 display="flex"
                 justifyContent="center"
                 padding="8">
                {/*<h1>Middle</h1>*/}
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem/>
            <Box flex="1" padding="8">
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <EmailIcon sx={{marginRight: "10px"}}/>
                            <Link href="mailto:jg@cbusha.com" color="inherit">
                                <ListItemText primary="Email us at jg@cbusha.com"/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ChatIcon sx={{marginRight: "10px"}}/>
                            <Link href="https://discord.com/channels/cbusha" color="inherit">
                                <ListItemText primary="Chat with us on Discord"/>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <List style={{display: 'flex', flexDirection: 'row'}}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <FacebookIcon/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <YouTubeIcon/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </ListItem>
                </List>
            </Box>
        </Box>

    )
}

function Splash() {
    const {isAuthenticated, isLoading} = useAuth0();
    if (isLoading) {
        return null; // or replace with a loading spinner
    }
    return (
        <React.Fragment>
            <Routes>
                <Route path="/customer" element={<CustomerList/>}/>
                <Route path="/contact" element={<ContactForm/>}/>
                <Route path="/id-claims" element={<IdClaimsTable/>}/>
                <Route path="/finance" element={<FinanceDashboard/>}/>
                <Route path="/" element={<Home/>}/>
            </Routes>
            <Footer/>
        </React.Fragment>
    );
}

export {Splash};
