import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from 'react-router-dom';

const FinanceDashboard = () => {
    const [transactions, setTransactions] = React.useState([]);
    const {getAccessTokenSilently} = useAuth0();
    const [hasFinancePermission, setHasFinancePermission] = React.useState(false);
    const navigate = useNavigate(); // Initialize navigate

    React.useEffect(() => {
        const checkPermissions = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode(token);
                if (decodedToken.permissions && decodedToken.permissions.includes('finance')) {
                    setHasFinancePermission(true);
                    // const response = await fetch('/api/finance/transactions');
                    // console.log("Lets crack this bad boy open", response);
                    // const data = await response.json();
                    // setTransactions(data);
                } else {
                    navigate('/'); // Redirect to home if no finance permission
                }
            } catch (error) {
                console.error("Error in checking permissions", error);
            }
        };
        checkPermissions().then(r => console.log("Checked permissions"));
    }, [getAccessTokenSilently]);
    return (
        <div>
            <h1>Finance Dashboard</h1>
        </div>
    )
}
export default FinanceDashboard;