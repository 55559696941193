import React, {useState} from 'react';
import {Box, Button, Card, CardContent, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {BlankHeader} from "../Header/BlankHeader.jsx";

const ScrollableBox = styled(Box)({
    overflow: 'auto',
    height: '100%',
    width: '100%',
});

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    width: '100%',
});

const HeaderTypography = styled(Typography)({
    backgroundColor: '#157265',
    color: 'white',
    padding: '10px',
});

const MainCardContent = styled(CardContent)({
    backgroundColor: '#5abfb8',
});

export const ContactForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        datetimeRange: '',
        location: '',
        description: '',
        contactinfo: '',
        name: '',
        address: '',
        email: '',
        phone: ''
    });
    const [contactMethod, setContactMethod] = useState('email');

    const handleContactMethodChange = (event, newMethod) => {
        if (newMethod !== null) {
            setContactMethod(newMethod);
            setFormData({
                ...formData,
                contactinfo: formData[newMethod]
            });
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            contactinfo: e.target.name === contactMethod ? e.target.value : formData.contactinfo
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedFormData = {
            ...formData,
            contactinfo: formData[contactMethod]
        };
        const body = Object.keys(updatedFormData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(updatedFormData[key])).join('&');
        console.log("The body is: " + body);
        fetch('/services/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        })
            .then(() => navigate('/'))
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <ScrollableBox>
            <BlankHeader/>
            <CenteredBox>
                <Card sx={{minWidth: 275}}>
                    <HeaderTypography variant="h4" gutterBottom>
                        Contact Form
                    </HeaderTypography>
                    <MainCardContent>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name="name"
                                label="Who are you?"
                                fullWidth
                                value={formData.name}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="address"
                                label="Where is the gig?"
                                fullWidth
                                value={formData.address}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="description"
                                label="What can we do for you?"
                                fullWidth
                                multiline
                                rows={4}
                                value={formData.description}
                                onChange={handleChange}
                                margin="normal"
                            />

                            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                                <ToggleButtonGroup
                                    value={contactMethod}
                                    exclusive
                                    onChange={handleContactMethodChange}
                                    aria-label="contact method"
                                >
                                    <ToggleButton value="email" aria-label="email">
                                        Email
                                    </ToggleButton>
                                    <ToggleButton value="phone" aria-label="phone">
                                        Phone
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {contactMethod === 'email' ? (
                                    <TextField
                                        name="email"
                                        label="Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                        margin="normal"
                                        style={{flex: 1, marginLeft: 8}}
                                    />
                                ) : (
                                    <TextField
                                        name="phone"
                                        label="Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        margin="normal"
                                        style={{flex: 1, marginLeft: 8}}
                                    />
                                )}
                            </Box>
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </form>
                    </MainCardContent>
                </Card>
            </CenteredBox>
        </ScrollableBox>
    );
};