import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';

const Customer = ({customerId}) => {
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await fetch(`http://localhost:8080/customer/${customerId}`);
                const data = await response.json();
                setCustomer(data);
            } catch (error) {
                console.error('Error fetching customer:', error);
            }
        };

        fetchCustomer();
    }, [customerId]);

    if (!customer) {
        return null;
    }

    return (
        <div>
            <Typography variant="h6">{`${customer.firstName} ${customer.lastName}`}</Typography>
            {/* Add additional customer details rendering here */}
        </div>
    );
};

export default Customer;