import React, {useState} from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';

export function MarketedServices() {
    const [services, setServices] = useState([
        {
            title: 'Columbus-Based Expert Support',
            description: 'Rooted in Columbus, we employ bonded electricians for assured quality and proximity for swift assistance. Benefit from direct access to expert help with our responsive subscription service.'
        },
        {
            title: 'Versatile Open Source Integration',
            description: 'Leverage Home Assistant\'s expansive device compatibility, from thermostats to cameras and energy systems. Ideal for managing solar setups and sophisticated electrical monitoring solutions like Sense.'
        },
        {
            title: 'Tailored Smart Home Design',
            description: 'Specializing in custom dashboard creations, intricate Home Assistant configurations, and seamless integrations. We also offer specialized PCB outsourcing for bespoke hardware solutions.'
        },
    ]);

    const [marketing, setMarketing] = useState({
        title: "Discover Smart Living in Columbus",
        subtitle: "Explore our range of tailored home automation solutions.",
        body: "At the forefront of smart home technology, we're dedicated to bringing custom automation solutions to Columbus homes. Our focus is on transforming your living space with innovative, personalized systems that cater to your unique lifestyle. Interested in learning more? Click the 'Contact Us' button to provide your details and sign up for further information about our services. We're excited to help you step into a smarter, more connected home experience."
    })

    const addService = () => {
        if (services.length < 4) {
            setServices([...services, {
                title: `Service ${services.length + 1}`,
                description: `This is service ${services.length + 1}`
            }]);
        }
    };

    const removeService = (index) => {
        setServices(services.filter((_, i) => i !== index));
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Card>
                <CardContent>
                    <Typography variant="h4" gutterBottom textAlign="center" color={"black"}>
                        {marketing.title}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom textAlign="center" color={"black"}>
                        {marketing.subtitle}
                    </Typography>
                    <Typography variant="body1" gutterBottom textAlign="center" color={"black"}>
                        {marketing.body}
                    </Typography>
                </CardContent>
            </Card>

            <Grid container direction="row" alignItems="stretch" justifyContent="center">
                {services.map((service, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} style={{display: 'flex'}}>
                        <Card style={{display: 'flex', borderRadius: 0}}>
                            <CardContent style={{flex: 1}}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    textAlign="center"
                                    fontWeight="bold"
                                >
                                    {service.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    textAlign="center"
                                    color="text.secondary">
                                    {service.description}
                                </Typography>
                                {/*<Button variant="contained" */}
                                {/*        color="secondary"*/}
                                {/*        onClick={() => removeService(index)}>*/}
                                {/*    Remove*/}
                                {/*</Button>*/}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {/*<Button variant="contained" color="primary" onClick={addService}>Add Service</Button>*/}
        </Grid>
    );
}